<template>
  <div class="animated fadeIn">
    <add-person :label="'پزشک'" :job="'doctor'"></add-person>
  </div>
</template>

<script>
const addPerson = () => import("../components/hr/AddPerson.vue");

export default {
  components: {
    addPerson
  },
};
</script>